export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1648745895/sollee/Group_609_5.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/facebook_white.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1648745730/sollee/Group_610_9.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/instagram_white.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1591377852/general/cflogo_black.png'
export const about =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1655137842/chefsushi/about.jpg'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1654542910/chefsushi/footer_1.jpg'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1655137527/chefsushi/heroBg.jpg'
export const heroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1654540540/chefsushi/heroText_2.png'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1654540525/chefsushi/Group_1540.png'
export const logoMobile =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1654540525/chefsushi/Group_1540.png'
export const locationMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1654542778/chefsushi/map_2_1.jpg'
export const mobileAbout =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1648711269/sollee/Group_1323_2_1.jpg'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1654542929/chefsushi/Mask_group_10_1.jpg'
export const mobileHero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1655138133/chefsushi/mobileHero.jpg'
export const mobileMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1654542928/chefsushi/Group_1511_2.jpg'
export const mobileHeroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1654542665/chefsushi/heroText_3.png'
export const mobilePromotion =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1655138201/chefsushi/mobilePromo.jpg'
export const orderPickupButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1654540544/chefsushi/OrderPickupButton_2.png'
export const promotions =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1654542890/chefsushi/promotions_4_1.jpg'
export const mobileAboutDivider =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1648710686/sollee/bibimbap-dark-concrete-background-traditional-korean-dish-top-view_3_1.jpg'
export const aboutDivider =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1648710513/sollee/Mask_group_55_1.jpg'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1654542776/chefsushi/Frame_4_1.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1655138309/chefsushi/mobileGallery.jpg'
